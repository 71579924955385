<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="paymodeForm" :rules="rules">
      <!-- 顶部按钮 -->
      <div class="btnTOP">
        <el-button type="primary" size="mini" @click="submitForm">保存 </el-button>
        <el-button @click="cancel" size="mini">退出</el-button>
      </div>
      <cardTitleCom cardTitle="基本信息">
        <template slot="cardContent">
          <div class="tableContent x-x">
            <el-form-item label="方案编号" label-width="80px" prop="payChannelNo">
              <el-input
                v-model="paymodeForm.payChannelNo"
                class="inputWidthM"
                v-no-space
              ></el-input>
            </el-form-item>
            <el-form-item label="方案名称" label-width="80px" prop="payChannelName">
              <el-input
                v-model="paymodeForm.payChannelName"
                class="inputWidthM"
                v-no-space
              ></el-input>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="商户参数">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-w">
              <el-form-item label="接入类型" label-width="80px" prop="payChannelType">
                <el-select
                  v-model="paymodeForm.payChannelType"
                  placeholder="请选择接入类型"
                  clearable
                >
                  <el-option
                    v-for="dict in dict.type.shop_pay_type"
                    :key="dict.value"
                    :label="dict.label"
                    :value="parseInt(dict.value)"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="第三方支付AppID"
                label-width="140px"
                v-if="paymodeForm.payChannelType == 1 || paymodeForm.payChannelType == 2"
                prop="merchantAppId"
              >
                <el-input
                  v-model="paymodeForm.merchantAppId"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="第三方支付密钥(Secret)"
                label-width="175px"
                v-if="paymodeForm.payChannelType == 1 || paymodeForm.payChannelType == 2"
                prop="merchantKey"
              >
                <el-input
                  v-model="paymodeForm.merchantKey"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="第三方支付商户号"
                label-width="135px"
                v-if="paymodeForm.payChannelType == 1 || paymodeForm.payChannelType == 2"
                prop="merchantNo"
              >
                <el-input
                  v-model="paymodeForm.merchantNo"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="第三方支付子商户AppID"
                label-width="180px"
                v-if="paymodeForm.payChannelType == 2"
                prop="merchantSubAppId"
              >
                <el-input
                  v-model="paymodeForm.merchantSubAppId"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="第三方支付子商户号"
                label-width="150px"
                v-if="paymodeForm.payChannelType == 2"
                prop="merchantSubNo"
              >
                <el-input
                  v-model="paymodeForm.merchantSubNo"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="上传证书"
                v-if="paymodeForm.payChannelType == 1 || paymodeForm.payChannelType == 2"
                required
                class="x-f"
              >
                <OssUpload
                  v-model="url"
                  :type="uploadTypeArr"
                  :uploadFile="fileList"
                  uploadType="base64"
                  @change="successFile"
                  useFileName
                />
              </el-form-item>
              <el-form-item
                label="支付宝账号"
                label-width="97px"
                v-if="paymodeForm.payChannelType == 3 || paymodeForm.payChannelType == 4"
                prop="merchantNo"
              >
                <el-input
                  v-model="paymodeForm.merchantNo"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="支付宝AppID"
                label-width="108px"
                v-if="paymodeForm.payChannelType == 3 || paymodeForm.payChannelType == 4"
                prop="merchantAppId"
              >
                <el-input
                  v-model="paymodeForm.merchantAppId"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="子商户AppToken"
                label-width="140px"
                v-if="paymodeForm.payChannelType == 4"
                prop="merchantKey"
              >
                <el-input
                  v-model="paymodeForm.merchantKey"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="支付宝公有密钥"
                label-width="125px"
                v-if="paymodeForm.payChannelType == 3 || paymodeForm.payChannelType == 4"
                prop="merchantPublicKey"
              >
                <el-input
                  v-model="paymodeForm.merchantPublicKey"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="客户私有密钥"
                label-width="120px"
                v-if="paymodeForm.payChannelType == 3 || paymodeForm.payChannelType == 4"
                prop="merchantPrivateKey"
              >
                <el-input
                  v-model="paymodeForm.merchantPrivateKey"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="商户号"
                label-width="70px"
                v-if="paymodeForm.payChannelType == 5 || paymodeForm.payChannelType == 6"
                prop="merchantNo"
              >
                <el-input
                  v-model="paymodeForm.merchantNo"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="终端号"
                label-width="70px"
                v-if="paymodeForm.payChannelType == 5 || paymodeForm.payChannelType == 6"
                prop="merchantTerminalNo"
              >
                <el-input
                  v-model="paymodeForm.merchantTerminalNo"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="授权码"
                label-width="70px"
                v-if="paymodeForm.payChannelType == 5"
                prop="merchantKey"
              >
                <el-input
                  v-model="paymodeForm.merchantKey"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
              <el-form-item
                label="门店号"
                label-width="70px"
                v-if="paymodeForm.payChannelType == 6"
                prop="merchantStoreNo"
              >
                <el-input
                  v-model="paymodeForm.merchantStoreNo"
                  class="inputWidthM"
                  v-no-space
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import OssUpload from "@/components/oss-upload/index.vue";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import {
  payChannelAddAPI,
  payChannelDetailAPI,
  payChannelUpdateAPI,
} from "@/api/shop/base/payChannel"; //支付通道
export default {
  name: "payChannelDetail",
  dicts: ["shop_pay_type"],
  components: { cardTitleCom, OssUpload },
  data() {
    return {
      uploadTypeArr: [], // 上传文件类型
      url: "", // 上传文件路径
      fileList: [], // 回显的文件
      //支付表单
      paymodeForm: {},
      //旧的表单
      oldForm: {},
      // 遮罩层
      loading: false,
      // 表单校验
      rules: {
        payChannelNo: [
          {
            required: true,
            message: "方案编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        payChannelName: [
          {
            required: true,
            message: "方案名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        payChannelType: [
          {
            required: true,
            message: "支付通道类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        merchantAppId: [
          {
            required: true,
            message: "第三方支付AppID不能为空",
            trigger: ["blur", "change"],
          },
        ],
        merchantKey: [
          {
            required: true,
            message: "第三方支付密钥不能为空",
            trigger: ["blur", "change"],
          },
        ],
        merchantNo: [
          {
            required: true,
            message: "第三方支付商户号不能为空",
            trigger: ["blur", "change"],
          },
        ],

        merchantPrivateKey: [
          {
            required: true,
            message: "第三方私有密钥不能为空",
            trigger: ["blur", "change"],
          },
        ], //一个 已
        merchantPublicKey: [
          {
            required: true,
            message: "第三方公有密钥",
            trigger: ["blur", "change"],
          },
        ], //1个 已
        merchantSubAppId: [
          {
            required: true,
            message: "第三方支付子商户AppID不能为空",
            trigger: ["blur", "change"],
          },
        ], //1个  已
        merchantSubNo: [
          {
            required: true,
            message: "第三方支付子商户号不能为空",
            trigger: ["blur", "change"],
          },
        ], //1个 已
        merchantTerminalNo: [
          {
            required: true,
            message: "第三方终端号不能为空",
            trigger: ["blur", "change"],
          },
        ], //1个 已
        merchantStoreNo: [
          {
            required: true,
            message: "门店号不能为空",
            trigger: ["blur", "change"],
          },
        ], //1个 已
      },
    };
  },
  watch: {
    "paymodeForm.payChannelType": {
      handler(newVal) {
        if (newVal == 1) {
          //1、2
          //第三方支付AppID
          this.rules.merchantAppId[0].required = true;
          this.rules.merchantAppId[0].message = "第三方支付AppID不能为空";
          //第三方支付密钥
          this.rules.merchantKey[0].required = true;
          this.rules.merchantKey[0].message = "第三方支付密钥不能为空";
          //第三方支付商户号
          this.rules.merchantNo[0].required = true;
          this.rules.merchantNo[0].message = "第三方支付商户号不能为空";

          //非必选
          //第三方私有密钥不能为空
          this.rules.merchantPrivateKey[0].required = false;
          //第三方公有密钥不能为空
          this.rules.merchantPublicKey[0].required = false;
          //第三方支付子商户AppID不能为空
          this.rules.merchantSubAppId[0].required = false;
          //第三方支付子商户号不能为空
          this.rules.merchantSubNo[0].required = false;
          //第三方终端号不能为空
          this.rules.merchantTerminalNo[0].required = false;
        } else if (newVal == 2) {
          //1、2
          //第三方支付AppID
          this.rules.merchantAppId[0].required = true;
          this.rules.merchantAppId[0].message = "第三方支付AppID不能为空";
          //第三方支付密钥
          this.rules.merchantKey[0].required = true;
          this.rules.merchantKey[0].message = "第三方支付密钥不能为空";
          //第三方支付商户号
          this.rules.merchantNo[0].required = true;
          this.rules.merchantNo[0].message = "第三方支付商户号不能为空";
          //2
          //第三方支付子商户AppID
          this.rules.merchantSubAppId[0].required = true;
          //第三方支付子商户号
          this.rules.merchantSubNo[0].required = true;

          //非必选
          //第三方私有密钥不能为空
          this.rules.merchantPrivateKey[0].required = false;
          //第三方公有密钥不能为空
          this.rules.merchantPublicKey[0].required = false;
          //第三方终端号不能为空
          this.rules.merchantTerminalNo[0].required = false;
        } else if (newVal == 3) {
          //第三方支付商户号\支付宝账号
          this.rules.merchantNo[0].required = true;
          this.rules.merchantNo[0].message = "支付宝账号不能为空";
          //第三方支付AppID\支付宝AppID
          this.rules.merchantAppId[0].required = true;
          this.rules.merchantAppId[0].message = "支付宝AppID不能为空";
          //支付公有密钥
          this.rules.merchantPublicKey[0].required = true;
          //客户私有密钥
          this.rules.merchantPrivateKey[0].required = true;

          //非必选
          //第三方支付密钥
          this.rules.merchantKey[0].required = false;
          //第三方支付子商户AppID不能为空
          this.rules.merchantSubAppId[0].required = false;
          //第三方支付子商户号不能为空
          this.rules.merchantSubNo[0].required = false;
          //第三方终端号不能为空
          this.rules.merchantTerminalNo[0].required = false;
        } else if (newVal == 4) {
          //第三方支付商户号\支付宝账号
          this.rules.merchantNo[0].required = true;
          this.rules.merchantNo[0].message = "支付宝账号不能为空";
          //第三方支付AppID\支付宝AppID
          this.rules.merchantAppId[0].required = true;
          this.rules.merchantAppId[0].message = "支付宝AppID不能为空";
          //第三方支付密钥\子商户AppToken
          this.rules.merchantKey[0].required = true;
          this.rules.merchantKey[0].message = "子商户AppToken不能为空";
          //支付公有密钥
          this.rules.merchantPublicKey[0].required = true;
          //客户私有密钥
          this.rules.merchantPrivateKey[0].required = true;

          //非必选
          //第三方支付子商户AppID不能为空
          this.rules.merchantSubAppId[0].required = false;
          //第三方支付子商户号不能为空
          this.rules.merchantSubNo[0].required = false;
          //第三方终端号不能为空
          this.rules.merchantTerminalNo[0].required = false;
        } else if (newVal == 5) {
          //第三方支付商户号\支付宝账号\商户号
          this.rules.merchantNo[0].required = true;
          this.rules.merchantNo[0].message = "商户号";
          //终端号
          this.rules.merchantTerminalNo[0].required = true;
          this.rules.merchantTerminalNo[0].message = "终端号";
          //第三方支付密钥\子商户AppToken\授权码
          this.rules.merchantKey[0].required = true;
          this.rules.merchantKey[0].message = "授权码不能为空";

          //非必选
          //第三方公有密钥不能为空
          this.rules.merchantPublicKey[0].required = false;
          //第三方支付子商户AppID不能为空
          this.rules.merchantSubAppId[0].required = false;
          //第三方支付子商户号不能为空
          this.rules.merchantSubNo[0].required = false;
          //第三方支付AppID
          this.rules.merchantAppId[0].required = false;
          //第三方私有密钥不能为空
          this.rules.merchantPrivateKey[0].required = false;
        } else if (newVal == 6) {
          //第三方支付商户号\支付宝账号\商户号
          this.rules.merchantNo[0].required = true;
          this.rules.merchantNo[0].message = "商户号";
          //终端号
          this.rules.merchantTerminalNo[0].required = true;
          this.rules.merchantTerminalNo[0].message = "终端号";
          //门店号
          this.rules.merchantStoreNo[0].required = true;
          this.rules.merchantStoreNo[0].message = "门店号不能为空";
          //非必选
          //第三方支付密钥\子商户AppToken\授权码
          this.rules.merchantKey[0].required = false;
          //第三方公有密钥不能为空
          this.rules.merchantPublicKey[0].required = false;
          //第三方支付子商户AppID不能为空
          this.rules.merchantSubAppId[0].required = false;
          //第三方支付子商户号不能为空
          this.rules.merchantSubNo[0].required = false;
          //第三方支付AppID
          this.rules.merchantAppId[0].required = false;
          //第三方私有密钥不能为空
          this.rules.merchantPrivateKey[0].required = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.payChannelId) {
          //获取单据详情
          const { data } = await payChannelDetailAPI(vm.$route.query.payChannelId);
          //单据赋值
          vm.paymodeForm = data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.paymodeForm));
          // 回显文件
          if (data.merchantCertFile) {
            const dataURLtoFile = (dataurl, filename) => {
              var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              return new File([u8arr], filename, { type: mime });
            };
            const file = dataURLtoFile(data.merchantCertFile, "apiclient_cert.p12");
            vm.fileList.push(file);
            console.log("文件列表", vm.fileList);
          }
        }
      } else {
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.paymodeForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.paymodeForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.paymodeForm.payChannelId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.paymodeForm));
    }
    next();
  },
  directives: {
    "no-space": {
      // inserted (el) {
      //   el.addEventListener('input', function () {
      //     this.value = this.value.replace(/\s/g, '')
      //   })
      // }
    },
  },
  methods: {
    // 上传证书成功
    successFile(file) {
      this.paymodeForm.merchantCertFile = file ? file.fileBase64 : undefined;
    },
    //取消
    cancel() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$router.back();
      this.$destroy();
    },
    // 表单重置
    reset() {
      this.paymodeForm = {};
      this.resetForm("form");
    },
    /** 提交按钮 */
    async submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.paymodeForm.payChannelId) {
            await payChannelUpdateAPI(this.paymodeForm);
            this.$modal.msgSuccess("修改单据成功");
          } else {
            console.log("555");
            await payChannelAddAPI(this.paymodeForm);
            this.$modal.msgSuccess("新增单据成功");
          }
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              (item) => item.path === this.$route.path
            ),
            1
          );
          this.$router.back();
          this.$destroy();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  padding: 6px 10px 1px 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //表格
  .tableContent {
    padding: 6px 10px;
  }
}
//内部表单
::v-deep .el-form-item {
  margin-bottom: 20px;
}
</style>
