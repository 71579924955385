<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { allStoreList } from "@/api/system/store"; //仓库
import { updateInstoreStatus } from "@/api/purchase/index";
// import { httpCheckList } from "@/api/store/index";
import { listOrder, updateOrderStatus, copyShopBillCheck, listOrderSummary } from "@/api/shop/bill/check"; //门店盘点
import { httpCheckList } from "@/api/store/index";
import Dialog from "@/components/Dialog";
const { cloneDeep } = require("lodash");

export default {
  name: "unsaleGoods",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        type: "StoreBillCheck",
        show: false,
      },
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        exportOption: {
          show: true,
          exportApi: "shopCheckExportList",
          exportName: "门店盘点列表",
          timeout: 60 * 5 * 1000,
        },
        getListApi: listOrder,
        getSummaryApi: listOrderSummary,
        body: {},
        defaultBody: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        uuid: "6f6e01b3-16bf-b926-3efb-43aab5cd069f",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "单据编号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNos",
          },
          {
            label: "盘点名称",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNames",
          },
          this.$select({
            key: "listInventory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "bill_status",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            // btnType: "dropdown",
            disabled: false,
            // other: [
            //   {
            //     click: "redoAudit",
            //     label: "反审核",
            //     disabled: false,
            //   },
            // ],
          },
          {
            click: "stop",
            label: "终止",
            icon: "el-icon-remove-outline",
            type: "primary",
            disabled: false,
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: false,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 160,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 100,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 100,
          },
          {
            prop: "checkName",
            label: "盘点名称",
            minWidth: 140,
          },
          {
            prop: "storeName",
            label: "盘点仓库",
            minWidth: 160,
          },
          {
            prop: "unitSaleMoney",
            label: "盘点售价金额",
            minWidth: 120,
          },
          {
            prop: "checkFullBillNo",
            label: "盘盈单号",
            type: "link",
            click: "routerLinkBill",
            minWidth: 160,
          },
          {
            prop: "checkLoseBillNo",
            label: "盘亏单号",
            type: "link",
            click: "routerLinkLose",
            minWidth: 160,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 155,
          },
          {
            prop: "createEmployee",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
        ],
        summary: ["unitSaleMoney"],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      // let url = "/api/system/store/check/updateBillStatus";
      let url = "/api/system/shop/bill/check/updateBillStatus";
      let billType = "107";
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "checkDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "routerLinkBill":
          {
            const billId = row.checkFullBillId;
            this.$router.push({
              name: "storeCheckFullDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "routerLinkLose":
          {
            const billId = row.checkLoseBillId;
            this.$router.push({
              name: "storeCheckLoseDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "dialogChange":
          {
            this.$router.push({
              name: "checkDetail",
              query: {
                type: "Update",
                billId: row?.formData?.formApiRes?.data?.billId,
              },
            });
          }
          break;
        case "add":
          {
            this.dialogOptions = {
              ...this.dialogOptions,
              show: true,
              click: type,
              data: {
                type: "140309",
              },
              title: "盘点向导",
            };
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData, url, billType);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData, url, billType);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData, url, billType);
          }
          break;
        case "stop":
          {
            if (!selectData.length) return;
            this.handleBillStatus("关闭", selectData, url, billType);
          }
          break;
        case "getListed":
          {
            this.options.body.billStatus = undefined;
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            copyShopBillCheck(billIds).then((res) => {
              this.$message.success("复制成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() { },
    handleBillStatus(command, selectData, url, BillType) {
      let billData = []; //过滤的数据
      let name = ""; //要执行的状态名字
      let tag = ""; //提示的状态
      let billStatus = ""; //要执行的状态数字
      if (command === "反审核") {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = "反审核";
        tag = "已审核";
        billStatus = "0";
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === "审核") {
          name = "审核";
          tag = "未审核";
          billStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          tag = "未审核";
          billStatus = "3";
        } else if (command === "关闭") {
          name = "关闭";
          tag = "未审核";
          billStatus = "4";
        }
      }
      const billId = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus([...billId], billStatus, url, BillType).then(() => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            $this.$modal.msgSuccess(`${name}成功`);
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
          });
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.options.defaultBody = { billStatuss: [to.query.billStatus] };
        vm.options.search[5].model = [to.query.billStatus];
        vm.options.search[1].defaultValue = "";
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
